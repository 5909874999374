import { call, put } from "redux-saga/effects";
import {
  GET_ITEM_FETCH_REQUEST,
  GET_ITEM_FETCH_SUCCESS,
  GET_ITEM_FETCH_FAILURE,
  // UPDATE_PRICE_INVENTORY,
  SUPPLIER_INFO_SUCCESS,
  ADD_TO_CART_MODAL_CLOSE,
  CHANGE_PRODUCT_ATTRIBUTES,
  CHANGE_TITLE_AND_LONGDESC,
  REQUEST_BASKET_AFTER_ADDING_TO_CART,
  SHOW_CONTINUE_MODAL,
  UNMOUNT_PRODUCT_PAGE,
  SET_REVIEW_MODAL_STATE,
  SHOW_CONTINUE_MODAL_QUOTE,
  SUCECSS_MAIN_PRODUCT_SKUS_N_SKUIDS,
  FAILURE_MAIN_PRODUCT_SKUS_N_SKUIDS,
  REQUEST_MAIN_PRODUCT_SKUS_N_SKUIDS,
  SET_ATTRIBUTES_DETAILS,
  SET_CART_VALIDATION_ERR,
  POPULATE_ACCESSORY_MODAL,
  CLOSE_ACCESSORY_MODAL,
  GET_MODAL_ITEM_FETCH_REQUEST,
  GET_MODAL_ITEM_FETCH_SUCCESS,
  deliveryOptionsActions,
  addToCartActions,
  UPDATE_ACCESSORY_INVENTORY,
  SET_PRODUCT_SWITCH_IMAGE_FLAG,
  SET_PRODUCT_IMAGECAROUSEL_INITIAL,
  SET_PRODUCT_OUT_OF_STOCK_ERR,
  SET_PERSONALZED_QUESTIONS,
  SET_PERSONALZED_QUESTIONS_PROGRESS,
  SET_USER_INPUTS_PERSONALIZED_QUESTIONS,
  SET_CHEQUE_VALIDATIONS,
  SET_CHEQUE_VALIDATION_ERRORS,
  SET_BIID,
  CONFIRM_LEAVE,
  SAVE_INPUTS,
  SET_LOADING_FOR_REVIEW_PROCESS,
  SET_JUMP_TO_REVIEW_TAB
} from "../types.js";

import {
  GET_ITEM_LINK,
  GET_ID_PRODUCT_LINK,
  //GET_PRICE_INVENTORY,
  ADD_TO_CART,
  GET_DELIVERY_OPTIONS,
  //GET_SUPPLIER_INFO,
  ADD_TO_SUPP_CART,
  GET_PERSONALIZED_QUESTIONS,
  ADD_TO_CART_AND_LIFT_REVIEW_FLAG
} from "../links.js";

import { VID, PREVIEW } from "../../project-config";

import { store } from "../../index";
import { pad } from "../../functions/Utilities.js";

export const confirmLeaveAction = payload => ({
  type: CONFIRM_LEAVE,
  payload
});

export const unMountProductPageAction = () => ({
  type: UNMOUNT_PRODUCT_PAGE
});

/**
 * @param {boolean} payload           True or false.
 */
export const setProductSwitchImageAction = payload => ({
  type: SET_PRODUCT_SWITCH_IMAGE_FLAG,
  payload: payload
});

export const setProductImagecarouselInitialAction = payload => ({
  type: SET_PRODUCT_IMAGECAROUSEL_INITIAL,
  payload: payload
});

export const setAddToCartValidationErrors = payload => ({
  type: SET_CART_VALIDATION_ERR,
  payload: payload
});

export const setProductOutOfStockError = payload => ({
  type: SET_PRODUCT_OUT_OF_STOCK_ERR,
  payload: payload
});

export const setAttributesDetailsAction = payload => ({
  type: SET_ATTRIBUTES_DETAILS,
  payload: payload
});

export const setReviewModalStateAction = payload => ({
  type: SET_REVIEW_MODAL_STATE,
  payload: payload
});

export const fetchingProductRequestSaga = id => ({
  type: GET_ITEM_FETCH_REQUEST,
  payload: { id }
});

export const setUserInputsPersonalizedQuestions = payload => ({
  type: SET_USER_INPUTS_PERSONALIZED_QUESTIONS,
  payload
});

export const saveInputsAction = () => ({
  type: SAVE_INPUTS
});

export const setChequeValidationErrors = payload => ({
  type: SET_CHEQUE_VALIDATION_ERRORS,
  payload
});

export const setChequeValidations = payload => ({
  type: SET_CHEQUE_VALIDATIONS,
  payload
});

export const setPersonalizedQuestionsProgressAction = payload => ({
  type: SET_PERSONALZED_QUESTIONS_PROGRESS,
  payload
});

export const setPersonalizedQuestionsAction = payload => ({
  type: SET_PERSONALZED_QUESTIONS,
  payload
});

export const handleBiidAction = payload => ({
  type: SET_BIID,
  payload
});

export const displayLoadingForReviewProcessAction = payload => ({
  type: SET_LOADING_FOR_REVIEW_PROCESS,
  payload
});

export const jumpToReviewTabOnCustomizerAction = payload => ({
  type: SET_JUMP_TO_REVIEW_TAB,
  payload
});

const mapValidationField = validationField => {
  try {
    if (!!validationField === false || validationField.includes("$qdetail"))
      return {};

    let mappedValidationField = {};
    const validations = validationField.split(",");

    validations.forEach(validation => {
      if (validation.includes(":")) {
        const [firstPart, secondPart] = validation.split(":");
        if (validation.toLowerCase().includes("charlength")) {
          if (mappedValidationField.length === undefined) {
            mappedValidationField.length = {};
          }
          let key = "";
          if (firstPart.toLowerCase().includes("max")) key = "max";
          else if (firstPart.toLowerCase().includes("min")) key = "min";
          mappedValidationField.length = {
            ...mappedValidationField.length,
            [key]: parseInt(secondPart)
          };
        } else if (validation.toLowerCase().includes("type:")) {
          mappedValidationField[firstPart] = secondPart;
        }
      } else {
        mappedValidationField[validation] = true;
      }
    });

    return mappedValidationField;
  } catch (err) {
    console.error("Error mapping validation field", err);
    return {};
  }
};

const getProductType = () => {
  const productInitialState = store.getState().productReducer.productInitial;
  if (productInitialState && productInitialState.hiddenProperties) {
    const productType = productInitialState.hiddenProperties.find(
      prop => Object.keys(prop)[0].toLocaleLowerCase() === "product_type"
    );

    if (productType) {
      return Object.values(productType)[0];
    }
  }

  return "";
};

export const requestPersonalizedQuestionsAction = (
  dispatch,
  id,
  biid = null,
  options
) => {
  const productType = getProductType();
  const depositSlipProduct = productType.toLowerCase() === "deposit slip";

  fetch(GET_PERSONALIZED_QUESTIONS(id, biid))
    .then(res => res.json())
    .then(json => {
      let questions = {};
      let detailIDs = {
        startNumberDetailId: null,
        startNumberPrefixDetailId: null,
        transitNumberDetailId: null,
        institutionNumberDetailId: null,
        accountNumberDetailId: null,
        advertisingSloganDetailId: null,
        companyInformationQuestion: null,
        companyInformationQuestionDetailIds: null
      };

      let separateMICRLines = [];
      let depositSlipSingleAccountNumberDetailIdsToAppendAfterBranchAndInstitutionNumber =
        [];

      if (json && json[0] && json[0].questions) {
        let textFields = [];
        let restOfTheFields = [];
        let dependedFields = [];
        let maskedInputIds = [];
        let mappedValidationFields = {};

        json[0].questions.forEach(question => {
          // map validation field that is coming from the server
          question.quesDetails.forEach(detail => {
            const validationField = detail.validation;
            const mappedValidationField = mapValidationField(validationField);

            if (Object.keys(mappedValidationField).length > 0) {
              detail.validations = mappedValidationField;
            }

            mappedValidationFields = {
              ...mappedValidationFields,
              [detail.detailID]: mappedValidationField
            };
          });

          console.log("mappedValidationFields", mappedValidationFields);

          if (question.quesInput === "text") {
            // if it's logo id input move it to the dependedFields
            if (question.quesCode === "logoId") {
              question.quesDetails.forEach(detail => {
                detail.validations = {
                  /* length: { max: 30 }, */
                  type: "required",
                  for: "logoID",
                  message: {
                    existingLogo: "Please specify your Logo ID",
                    pickClipart: "Please select a clipart logo"
                  },
                  active: false,
                  setActiveOnMount: true,
                  ...mappedValidationFields[detail.detailID]
                };
              });
              question.dependedOn = "logoMode";
              question.logoModeRequiredState = ["pickClipart", "existingLogo"];
              dependedFields = [...dependedFields, question];
              return;
            } else {
              question.quesDetails.forEach(detail => {
                detail.validations = {
                  length: { max: 100 },
                  ...mappedValidationFields[detail.detailID]
                };
              });
            }

            // if it's trancode,TextOptions-SW,TextOptions-SW2, then it's a dependedField
            if (
              ["trancode", "TextOptions-SW", "TextOptions-SW2"].some(q =>
                question.quesCode.toLowerCase().includes(q.toLowerCase())
              )
            ) {
              dependedFields = [...dependedFields, question];
              return;
            }
            if (
              question.quesCode.toLowerCase().includes("bankinformation") ||
              question.quesDesc.toLowerCase().includes("bank information") ||
              question.quesCode.toLowerCase().includes("textoptions-")
            ) {
              const transitNumberQuesDetail = question.quesDetails.find(
                d => d.defWords === "comet_bankBranchCd"
              );

              if (
                transitNumberQuesDetail &&
                transitNumberQuesDetail.answer &&
                Number(transitNumberQuesDetail.answer) < 10000
              ) {
                transitNumberQuesDetail.answer = pad(
                  "00000",
                  Number(transitNumberQuesDetail.answer)
                );
              }

              if (question.quesDesc.toLowerCase().includes("imprint")) {
                detailIDs.companyInformationQuestion = question;

                detailIDs.companyInformationQuestionDetailIds =
                  question.quesDetails.map(detail => {
                    return detail.detailID;
                  });
              }

              const instituionNumberQuesDetail = question.quesDetails.find(
                d => d.defWords === "comet_bankCd"
              );

              const accountNumberQuesDetail = question.quesDetails.find(
                d => d.defWords === "comet_bankAcctCd"
              );

              if (accountNumberQuesDetail) {
                if (
                  accountNumberQuesDetail.previewVal
                    .toLowerCase()
                    .includes("separate") &&
                  !depositSlipProduct
                  // don't separate micr lines for deposit slip
                ) {
                  separateMICRLines.push(accountNumberQuesDetail.detailID);
                } else if (
                  accountNumberQuesDetail.previewVal
                    .toLowerCase()
                    .includes("nonmicrfont")
                ) {
                  depositSlipSingleAccountNumberDetailIdsToAppendAfterBranchAndInstitutionNumber.push(
                    accountNumberQuesDetail.detailID
                  );
                }

                detailIDs.accountNumberDetailId =
                  accountNumberQuesDetail.detailID;
                maskedInputIds.push(accountNumberQuesDetail.detailID);

                /*      accountNumberQuesDetail.validations = {
                  length: { max: 20 }
                }; */
              }

              if (transitNumberQuesDetail) {
                detailIDs.transitNumberDetailId =
                  transitNumberQuesDetail.detailID;

                /*    transitNumberQuesDetail.validations = {
                  length: { max: 9 }
                }; */
              }

              if (instituionNumberQuesDetail) {
                detailIDs.institutionNumberDetailId =
                  instituionNumberQuesDetail.detailID;

                /*    instituionNumberQuesDetail.validations = {
                  length: { max: 11 }
                }; */
              }
            }

            // if softwareEmailProof then add to the rest of the questions section
            if (question.quesCode === "softwareEmailProof") {
              question.quesDetails.forEach(detail => {
                detail.validations = {
                  /*    length: { max: 50 }, */
                  type: "email",
                  active: false,
                  ...mappedValidationFields[detail.detailID]
                };
              });
              restOfTheFields = [...restOfTheFields, question];
              return;
            }

            // imprint questions are not read only anymore
            if (
              question.quesCode
                .trim()
                .toLowerCase()
                .match(/textoptions-\w*/) ||
              question.quesCode
                .trim()
                .toLowerCase()
                .match(/textoption-\w*/)
            ) {
              question.quesDetails.forEach(detail => {
                if (detail.detailDesc.toLowerCase().includes("slogan")) {
                  detailIDs.advertisingSloganDetailId = {
                    detailID: detail.detailID,
                    quesID: question.quesID
                  };
                }
                detail.validations = {
                  length: { max: 60 },
                  ...mappedValidationFields[detail.detailID]
                };
                console.log("detail defwords", detail.defWords);

                if (
                  detail.defWords !== "comet_bankAcctCd" &&
                  detail.defWords !== "comet_bankBranchCd"
                )
                  detail.ignoreReadOnly = true;
              });
            }

            if (question.quesCode !== "TextOptions-LSRCK3") {
              if (question.quesCode === "splInstrDetails-Inst") {
                question.quesDetails.forEach(detail => {
                  if (detail.detailDesc === "Special Instructions") {
                    detail.validations = {
                      length: { max: 199 },
                      ...mappedValidationFields[detail.detailID]
                    };
                  }
                });
              }

              if (question.quesCode.toLowerCase().includes("nbringdetails")) {
                question.quesDetails.forEach(detail => {
                  // set the question details (start number and prefix) to ignore readonly
                  detail.ignoreReadOnly = true;

                  if (detail.detailDesc.toLowerCase() === "start number") {
                    detailIDs.startNumberDetailId = detail.detailID;
                    if (
                      detail.detailLabelText.toLowerCase() !==
                      "start number optional"
                    ) {
                      detail.ignoreReadOnly = true;
                      detail.validations = {
                        length: { min: 4, max: 7 },
                        digitsOnly: true,
                        ...mappedValidationFields[detail.detailID]
                      };
                    } else {
                      detail.ignoreReadOnly = true;
                      detail.validations = {
                        length: { allowBlank: true, min: 4, max: 7 },
                        digitsOnly: true,
                        ...mappedValidationFields[detail.detailID]
                      };
                    }
                  }
                  if (
                    detail.detailDesc.toLowerCase() === "start number prefix"
                  ) {
                    const maxLengthOption =
                      options && options.startNumberPrefixMaxLengthProp;
                    detailIDs.startNumberPrefixDetailId = detail.detailID;

                    detail.validations = {
                      ...mappedValidationFields[detail.detailID]
                      /*     length: { max: maxLengthOption ? maxLengthOption : 6 } */
                    };
                  }
                });
              }
              textFields = [...textFields, question];
            } else {
              let quesDetailsDescToExclude = [
                /*     "line of text above",
                "advertising slogan" */
              ];

              let tempQuestion = Object.assign({}, question);
              tempQuestion.quesDetails = question.quesDetails.filter(
                detail =>
                  !quesDetailsDescToExclude.some(desc =>
                    detail.detailDesc.toLowerCase().includes(desc)
                  )
              );

              textFields = [...textFields, tempQuestion];
              /* 
              let tempQuestion2 = Object.assign({}, question);

              tempQuestion2.quesDetails = question.quesDetails.filter(detail =>
                quesDetailsDescToExclude.some(desc =>
                  detail.detailDesc.toLowerCase().includes(desc)
                )
              );

              restOfTheFields = [...restOfTheFields, tempQuestion2]; */
            }
          } else {
            // if reverse numbering question, set the yes option to as an invalid input
            if (question.quesCode.toLowerCase().includes("reversenbringind")) {
              question.quesDetails.forEach(detail => {
                if (detail.detailDesc.toLowerCase() === "yes") {
                  detail.validations = { type: "invalid" };
                }
              });
            }
            if (question.quesDesc === "Logo Options")
              question.dependingFieldCode = "logoId";
            restOfTheFields = [...restOfTheFields, question];
          }
        });

        questions = {
          textFields,
          restOfTheFields,
          dependedFields,
          maskedInputIds
        };
      }
      dispatch(setPersonalizedQuestionsProgressAction({ separateMICRLines }));
      dispatch(
        setPersonalizedQuestionsProgressAction({
          depositSlipSingleAccountNumberDetailIdsToAppendAfterBranchAndInstitutionNumber
        })
      );
      dispatch(setPersonalizedQuestionsProgressAction({ detailIDs }));
      return dispatch(setPersonalizedQuestionsAction(questions));
    })
    .catch(err => {
      if (err.message.includes("Unexpected token < in JSON")) {
        dispatch(
          setPersonalizedQuestionsProgressAction({
            jsonIssue: { occured: true, modalActive: true }
          })
        );
      }
    });
};

export const addToCartModalClose = () => ({
  type: ADD_TO_CART_MODAL_CLOSE
});

const api = link =>
  fetch(link)
    .then(res => res.json())
    .then(json => {
      return {
        json: json.__Result[0]
      };
    });

export function* fetchFunctionSaga(action) {
  let language = store.getState().mainReducer.lang;
  try {
    let link = GET_ITEM_LINK;

    link = link
      .replace("$ITEMREPLACE", action.payload.id)
      .replace("$LANGUAGE", language);

    const product = yield call(api, link);

    yield put(fetchingItemSuccess(product.json, action.payload.id));
  } catch (e) {
    console.error("ERROR FETCH PRODUCT: ", e.message);
    fetchingItemFailure(e);
  }
}

export const fetchingItemSuccess = (json, id) => ({
  type: GET_ITEM_FETCH_SUCCESS,
  payload: { json, id }
});

export const fetchingItemFailure = error => ({
  type: GET_ITEM_FETCH_FAILURE,
  payload: error
});

export const changeTitleAndLongDesc = json => ({
  type: CHANGE_TITLE_AND_LONGDESC,
  payload: json
});

// export const fetchingProductPriceInventory = id => {
//   let language = store.getState().mainReducer.lang;
//   return dispatch => {
//     fetch(
//       GET_PRICE_INVENTORY.replace("$PRODUCT", id).replace("$LANGUAGE", language)
//     )
//       .then(res => res.json())
//       .then(json =>
//         dispatch({ type: UPDATE_PRICE_INVENTORY, payload: json.__Result[0] })
//       );
//   };
// };

export const fetchMainProductSkusAndSkuIds = id => {
  let language = store.getState().mainReducer.lang;

  let link = GET_ITEM_LINK;

  link = link.replace("$ITEMREPLACE", id).replace("$LANGUAGE", language);

  return dispatch => {
    dispatch({ type: REQUEST_MAIN_PRODUCT_SKUS_N_SKUIDS });
    fetch(link)
      .then(res => res.json())
      .then(json => {
        let skus = json.__Result[0].skus;
        let skuIds = json.__Result[0].skuids;
        dispatch({
          type: SUCECSS_MAIN_PRODUCT_SKUS_N_SKUIDS,
          payload: { mainProductSkus: skus, mainProductSkuIds: skuIds }
        });
      })
      .catch(err => {
        dispatch({ type: FAILURE_MAIN_PRODUCT_SKUS_N_SKUIDS });
      });
  };
};

export const reFetchProductInitialState = (link, itemid = null) => {
  if (itemid) {
    let language = store.getState().mainReducer.lang;

    link = ADD_TO_CART.replace("$PRODUCT", itemid).replace(
      "$LANGUAGE",
      language
    );
  }
  return dispatch => {
    fetch(link)
      .then(res => res.json())
      .then(json => {
        json = json && json[0];
        json.productLink = link;

        // Filtering out the reviews where the rating is 0
        if (json && json.reviews && json.reviews.length > 0) {
          json.reviews = json.reviews.filter(review => review.rating != 0);
        }

        dispatch(changeTitleAndLongDesc(json));
      })
      .catch(err => {
        console.error("ERROR REFETCH INITIAL: ", err.message);
        dispatch(fetchingItemFailure(err));
      });
  };
};

export const fetchDirectUrlGetItem = (url, langCode, countryCode) => {
  let tempUrl = url;
  let biid = "";
  let isReview = false;
  return dispatch => {
    tempUrl = tempUrl.replace(PREVIEW, "");

    if (!tempUrl.includes("product")) {
      if (tempUrl.includes(`/${langCode}/`)) {
        tempUrl = tempUrl.replace(`/${langCode}`, "");
      }

      tempUrl = "/product" + tempUrl;
    }
    let id = "";

    let link = GET_ID_PRODUCT_LINK.replace("$PRODUCT", tempUrl).replace(
      "$LANGUAGE",
      langCode
    );

    if (link.includes(`/${langCode}/`)) {
      link = link.replace(`${langCode}/`, "");
    }

    if (link.includes("biid/")) {
      const parts = link.split("biid/");

      if (parts && parts[1] && parseInt(parts[1])) {
        biid = parseInt(parts[1]);
      }

      link = link.split("/biid")[0] + `?tpt=json_${langCode}`;
    } else if (link.includes("biid-review/")) {
      isReview = true;
      const parts = link.split("biid-review/");

      if (parts && parts[1] && parseInt(parts[1])) {
        biid = parseInt(parts[1]);
      }

      link = link.split("/biid-review")[0] + `?tpt=json_${langCode}`;
    }
    dispatch(jumpToReviewTabOnCustomizerAction(isReview));

    dispatch(handleBiidAction({ biid, review: isReview }));

    if (tempUrl.includes("storeitem.html")) {
      id = tempUrl.split("iid=")[1];
      dispatch(fetchingProductRequestSaga(id));
      // dispatch(fetchingProductPriceInventory(id));
    } else {
      fetch(link)
        .then(res => {
          //return res.text();
          return res.json();
        })
        .then(json => {
          json = json && json[0];
          id = json.id;

          json.productLink = link;
          // Filtering out the reviews where the rating is 0
          if (json && json.reviews && json.reviews.length > 0) {
            json.reviews = json.reviews.filter(review => review.rating != 0);
          }

          dispatch(changeTitleAndLongDesc(json));
          dispatch(fetchingProductRequestSaga(id));
          // dispatch(fetchingProductPriceInventory(id));
        })
        .catch(err => {
          dispatch(fetchingItemFailure(err));
        });
    }
  };
};

// export const getSupplierInfo = id => {
//   let language = store.getState().mainReducer.lang;
//   return dispatch => {
//     fetch(
//       GET_SUPPLIER_INFO.replace("$PRODUCT", id).replace("$LANGUAGE", language),
//       { cache: "no-store" }
//     )
//       .then(res => res.json())
//       .then(json => {
//         dispatch({ type: SUPPLIER_INFO_SUCCESS, payload: json.__Result });
//       })
//       .catch(err => console.error(err));
//   };
// };

export const showContinueModalAfterAddingToCartAction = (
  quoteMode = false,
  payload
) => {
  return dispatch => {
    dispatch({
      type: quoteMode ? SHOW_CONTINUE_MODAL_QUOTE : SHOW_CONTINUE_MODAL,
      payload: payload
    });
  };
};

export const addToLocalMiniCart = (title, price, qty, supplier) => {
  return dispatch => {
    dispatch({
      type: SHOW_CONTINUE_MODAL,
      payload: { title, price, qty, supplier }
    });
  };
};
export const addToLocalMiniCartQute = (title, price, qty, supplier) => {
  return dispatch => {
    dispatch({
      type: SHOW_CONTINUE_MODAL_QUOTE,
      payload: { title, price, qty, supplier }
    });
  };
};

const requestDeliveryOptionsAction = payload => ({
  type: deliveryOptionsActions.REQUEST_DELIVERY_OPTIONS,
  payload: payload
});

export const getDeliveryOptions = (
  distributorId,
  invCode,
  qty,
  id,
  attributesObject,
  quoteMode = false,
  vid = null,
  biid = null,
  biidReview = null
) => {
  let language = store.getState().mainReducer.lang;
  return dispatch => {
    let historyID = "";
    let form = new FormData();
    form.append("basketeditmode", false);
    form.append("suppressautochooseinstock", false);

    if (distributorId) form.append("distributorId", distributorId);
    form.append("mode", "query");
    form.append("invcode", invCode);
    form.append("invhistid", "");
    form.append("qty", qty);
    form.append("vid", vid || VID);
    form.append("ml", language);

    /*     let tempJsonForm = {};
    form.forEach((value, key) => {
      tempJsonForm[key] = value;
    });
    let jsonForm = JSON.stringify(tempJsonForm); */

    dispatch(
      requestDeliveryOptionsAction({
        form,
        id,
        qty,
        distributorId,
        attributesObject,
        quoteMode,
        vid,
        biid,
        biidReview
      })
    );
  };
};

export const addToCartProduct = (
  id,
  qty,
  historyId,
  distributorId,
  attributesObject,
  quoteMode,
  vid,
  personalizedItem = false,
  notPersonalizedItemButHaveBundleItems = false,
  bundleItems = null,
  currentItemIsBundleItemState = null,
  bundleItemsProgressState = null,
  biid = null,
  biidReview = null
) => {
  let language = store.getState().mainReducer.lang;
  let parentBundleItemId = store.getState().productReducer.parentBundleItemId;
  //let itemIdState = store.getState().productReducer.itemDetail.itemid;
  return dispatch => {
    var form = new FormData();
    form.append(
      "productoption",
      `productoption.html?vid=${vid || VID}&cid=891&qp=0`
    );
    //  form.append("replaceditemmsg_0", "");
    form.append("viewMode", "item");
    form.append("actionMode", "buy");
    form.append("selfUri", "storeitem.html");
    form.append("targetUri", "basket.html");
    form.append(
      "mode",
      `${
        quoteMode
          ? "addQuote"
          : personalizedItem || notPersonalizedItemButHaveBundleItems
          ? "preAddItem"
          : "addItem"
      }`
    );
    form.append("itemscount", "1");
    form.append("_targetaddItem", `basket.html?vid=${vid || VID}`);
    quoteMode === "addQuote" &&
      form.append("_targetaddQuote", `basket.html?vid=${vid || VID}`);
    if (biid) {
      form.append("basketItems[0].basketItemId", biid);
      form.append("basketItems[0].editMode", true);
    }
    form.append("basketItems[0].itemId", id);
    form.append("basketItems[0].vendorId", vid || VID);
    form.append("basketItems[0].itemToProcess", true);
    form.append("basketItems[0].quantity", qty);

    form.append("basketItems[0].inventoryHistoryId", historyId);
    if (distributorId)
      form.append("basketItems[0].distributorId", distributorId);

    if (attributesObject != null) {
      let keys = Object.keys(attributesObject);
      let attributeIdandOptionIds = [];

      keys.forEach((key, keyIndex) => {
        let attribute = attributesObject[key];

        // Get only the attributeid and the optionid objects
        let attributeKeys = ["attributeid"];

        if (attribute && attribute.optionid) {
          attributeKeys.push("optionid");
        } else {
          attributeKeys.push("value");
        }

        attributeIdandOptionIds.push([
          attribute[attributeKeys[0]],
          attribute[attributeKeys[1]]
        ]);

        attributeKeys.forEach(key => {
          form.append(
            `basketItems[0].attributes[${keyIndex}].${key.replace("id", "Id")}`,
            attribute[key]
          );
        });
      });

      // append the last bit of attributeid and optonid part.
      if (attributeIdandOptionIds.length > 0) {
        for (let attributeAndOptionId of attributeIdandOptionIds) {
          if (
            attributesObject &&
            attributesObject[attributeAndOptionId[0]] &&
            attributesObject[attributeAndOptionId[0]].optionid
          ) {
            form.append(
              `attribute_${attributeAndOptionId[0]}`,
              attributeAndOptionId[1]
            );
          } else {
            let dataname = attributesObject[attributeAndOptionId[0]].dataname;
            form.append(`${dataname}`, attributeAndOptionId[1]);
          }
        }
      }
    }

    form.append("redirectMode", true);

    fetch(
      vid
        ? ADD_TO_SUPP_CART(vid, id, language)
        : ADD_TO_CART.replace("$PRODUCT", id).replace("$LANGUAGE", language),
      {
        method: "POST",
        body: form,
        headers: {
          Accept: "*/*",
          credentials: "same-origin"
        },
        mimeType: "multipart/form-data",
        data: form
      }
    )
      .then(res => {
        res.text();
      })
      .then(text => {
        if (!personalizedItem && !notPersonalizedItemButHaveBundleItems) {
          const payload = {
            vid: VID,
            languageState: language,
            biid,
            addedItemId: parentBundleItemId || id
          };
          dispatch(
            showContinueModalAfterAddingToCartAction(quoteMode, payload)
          );

          dispatch({
            type: addToCartActions.SUCCESS_ADD_TO_CART,
            payload: quoteMode ? "addQuote" : "addItem"
          });
        }
      })
      .then(() => {
        if (personalizedItem) {
          requestPersonalizedQuestionsAction(dispatch, id);
        } else if (
          bundleItemsProgressState !== null &&
          notPersonalizedItemButHaveBundleItems &&
          bundleItems &&
          bundleItems.length > 0 &&
          bundleItemsProgressState < bundleItems.length - 1
        ) {
          if (!currentItemIsBundleItemState) {
            const firstBundleItemId = bundleItems[0].id;
            dispatch(reFetchProductInitialState(null, firstBundleItemId));
            dispatch(fetchingProductRequestSaga(firstBundleItemId));
          } else {
            const nextBundleItemId =
              bundleItems[bundleItemsProgressState + 1].id;
            dispatch(reFetchProductInitialState(null, nextBundleItemId));
            dispatch(fetchingProductRequestSaga(nextBundleItemId));
          }
        } else {
          dispatch({ type: REQUEST_BASKET_AFTER_ADDING_TO_CART });
        }
      })
      .catch(err => {
        console.error(err, err.message);
      });
  };
};

export const changeProductAttributesAction = payload => ({
  type: CHANGE_PRODUCT_ATTRIBUTES,
  payload: payload
});

export const populateAccessoryModal = payload => ({
  type: POPULATE_ACCESSORY_MODAL,
  payload: payload
});

export const closeAccessoryModal = () => ({
  type: CLOSE_ACCESSORY_MODAL
});

export const fetchingModalProductRequestSaga = id => ({
  type: GET_MODAL_ITEM_FETCH_REQUEST,
  payload: { id }
});

export function* fetchModalProductAction(action) {
  let language = store.getState().mainReducer.lang;
  try {
    let link = GET_ITEM_LINK;

    link = link
      .replace("$ITEMREPLACE", action.payload.id)
      .replace("$LANGUAGE", language);

    const product = yield call(api, link);

    yield put(fetchingModalItemSuccess(product.json, action.payload.id));
  } catch (e) {
    console.error("MODAL FAILED", e);
  }
}

export const fetchingModalItemSuccess = (json, id) => ({
  type: GET_MODAL_ITEM_FETCH_SUCCESS,
  payload: { json, id }
});

// export const fetchingAccessoryPriceInventory = id => {
//   let language = store.getState().mainReducer.lang;
//   return dispatch => {
//     fetch(
//       GET_PRICE_INVENTORY.replace("$PRODUCT", id).replace("$LANGUAGE", language)
//     )
//       .then(res => res.json())
//       .then(json =>
//         dispatch({
//           type: UPDATE_ACCESSORY_INVENTORY,
//           payload: json.__Result[0]
//         })
//       );
//   };
// };
